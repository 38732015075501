<template>
  <div class="verification">
    <!-- <Header /> -->
    <div class="verification-header">
      <b-container class="verification-header-container">
        <b-row class="justify-content-center">
          <div class="verification-header text-center">
            <div class="my-4">
              <img src="@/assets/img/icons/mail-icon.svg" />
            </div>
            <h1>Verifikasi OTP</h1>
            <p>Masukkan kode verifikasi yang</p>
            <p>
              dikirim ke email <b>{{ checkEmail }}</b>
            </p>
            <p>
              Periksa email spam atau tab promosi jika tidak ada email masuk
              dari kami.
            </p>
            <div class="my-2 error-validation" v-if="failedVerifyShow">
              <p>Kode salah. Cek email untuk melihat kode yang kami kirimkan</p>
            </div>
            <div v-if="!isHidden">
              <vs-button v-on:click="isHidden = !isHidden" @click="resendOtp()" ref="loadableButton"
                id="button-with-loading" color="tertiary" type="relief" class="btn-resend">Kirim ulang kode
                verifikasi</vs-button>
            </div>
            <div v-if="isHidden">
              <div class="my-2 error-validation">
                <p>Cek email untuk melihat kode yang kami kirimkan</p>
              </div>
              <h5>{{ countDown }}</h5>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <div class="otp-field">
      <b-container class="otp-container">
        <b-form @submit.prevent="handleSubmit">
          <b-row class="justify-content-md-center my-4">
            <template>
              <div style="display: flex; flex-direction: row">
                <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4" v-model="otp"
                  :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange"
                  @on-complete="handleOnComplete" />
              </div>
            </template>
          </b-row>

          <div class="row justify-content-center my-5">
            <div class="col-lg-12 col-md-6 text-center">
              <b-button type="submit" :disabled="loading" @click.once="handleSubmit()" class="btn-tertiary py-2">
                <div v-if="loading">
                  <div class="spinner-border spinner-border-sm"></div>
                  Loading
                </div>
                <div v-else>Verifikasi Sekarang</div>
              </b-button>
            </div>
          </div>
        </b-form>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import axios from "axios";
import { encryptData } from '@/utils'
import instance from "@/utils/api";

export default {
  name: "Verfication",
  components: {
    // Header,
    Footer,
  },
  data() {
    return {
      otp: "",
      failedVerifyShow: false,
      checkEmail: "",
      client_id: "",
      checkPassword: "",
      countDown: 60,
      isHidden: false,
      loading: false,
      processing: false,
      registerTemp: {},
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
    ...mapActions({
      login: "auth/login",
    }),
  },
  mounted() {
    document.title = "Verifikasi OTP | CGS iTrade Fund";
    this.checkEmail = localStorage.getItem("client_email") || "-";
    this.client_id = localStorage.getItem("client_id") || "-";
    this.checkPassword = localStorage.getItem("client_password") || "-";

    if (this.checkEmail === "-" || this.checkPassword === "-") {
      alert("Invalid data");
      window.location = "/";
    }

    const registerTemp = JSON.parse(localStorage.getItem("registration_temp"));

    this.registerTemp = registerTemp;
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    handleOnComplete(value) {
      this.otp = value;
    },
    handleOnChange(value) {
      this.otp = value;
    },
    async handleSubmit() {
      const access_token = localStorage.access_token

      if (!this.processing) {
        this.processing = true;
        this.loading = !false;

        let data = new FormData();
        data.append("client_id", this.client_id);
        data.append("otp", this.otp);

        instance
          .post(`${this.app_url}auth/verify`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: access_token,
            },
          })

          .then(async () => {
            this.processing = false;
            this.failedVerifyShow = false;

            await axios.all([
              await instance.get(
                `${process.env.VUE_APP_ROOT_API}/sanctum/csrf-cookie`
              ),
              instance.post(`${this.app_url}auth/login`, {
                encryptedData: encryptData(JSON.stringify({
                  client_id: this.client_id,
                  password: this.checkPassword,
                }))
              }),
            ]);

            const registerTemp = this.registerTemp

            window.setTimeout(function () {
              const isFbonds = localStorage.getItem('is_fbonds')
              if (isFbonds)
                window.location.href = '/portfolio'
              else
                if (registerTemp.ClientType == 2)
                  window.location.href = "/institutional/pembukaan-akun";
                else
                  window.location.href = "/pembukaan-akun";
            }, 3000);
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.processing = false;
            this.failedVerifyShow = true;
          });
      }
    },
    async resendOtp() {
      const access_token = localStorage.access_token;
      if (!this.processing) {
        this.processing = true;

        instance
          .post(
            `${this.app_url}auth/otp`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: access_token,
              },
            }
          )

          .then((res) => {
            this.processing = false;
            console.log("RESPONSE RECEIVED: ", res);
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.processing = false;
            this.$vs.loading();
            setTimeout(() => {
              this.$vs.loading.close();
            }, 2000);

            this.countDownTimer();
            setTimeout(() => {
              this.isHidden = false;
              this.countDown = 60;
            }, 60000);
          });
      }
    },
  },
};
</script>

<style lang="scss" scooped>
.verification-header-container {
  padding-top: 120px;
  padding-bottom: 40px;
}

.otp-field {
  background: #f3f4f6;
  padding-top: 20px;
  padding-bottom: 40px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4b5563;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4b5563;
}

.error-validation p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #e53e3e !important;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

.verification-header h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}

.verification-header p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #374151;
  margin: 0px;
}

.otp-input {
  width: 80px;
  height: 80px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-otp {
  z-index: 99999;
}

.btn-tertiary {
  font-size: 14px;
}

.btn-resend {
  font-family: $inter;
  background: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: $secondaryColor;
}

.btn-resend:hover {
  background: none;
  color: $secondaryColor;
}

.btn-resend:focus {
  box-shadow: none !important;
}

.btn-resend:active {
  background: none !important;
  outline: none !important;
  color: $secondaryColor !important;
}

@media only screen and (max-width: 768px) {
  .otp-input {
    width: 78px !important;
    height: 78px !important;
  }

  p {
    font-size: 14px !important;
  }
}
</style>
